import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/scss/main.scss';
//import '../assets/scss/layout/_cookie.scss';


const Layout = ({ children, location }) => {

  const cookieName = "gatsby-gdpr-google-analytics";
  let content;

  if (location && location.pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>
          {children}
        </div>
      </div>
    )
  }

  return (
    <>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Sample' },
              { name: 'keywords', content: 'fiberswitch, it, technology, development' },
            ]}
          >
            <html lang="nl" />
          </Helmet>
          {content}
        </>
      )}
    />
    <CookieConsent
      //debug={true}
      cookieName={cookieName}
      sameSite="strict"
      onAccept={() => {
        //console.log('cookieName', cookieName)
        //Cookies.set(cookieName, "true" )
        //alert("Accept was triggered by clicking the Accept button");
      }}
      onDecline={() => {
        //Cookies.set(cookieName, "false" )
        //alert("nay!");
      }}
      expires={150}
      location="bottom"
      buttonText="OK"
      declineButtonText="Ik ga niet akkoord"
      enableDeclineButton
      flipButtons={true}
      disableStyles={true}
      declineButtonClasses="cookie-decline-button"
      buttonWrapperClasses="cookie-button-wrapper"
      buttonClasses="cookie-buttons"
      containerClasses="cookie-container"
      contentClasses="cookie-content"
    >
      Deze site kan gebruik maken van cookies om uw surf-ervaring te verbeteren. Gaat u hiermee akkoord?
    </CookieConsent>
  </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
